type KeyString = Record<string, string>;

interface ColorPalette {
  pink: KeyString;
  blue: KeyString;
  purple: KeyString;
  orange: KeyString;
  white: KeyString;
  gray: KeyString;
  black: KeyString;
}

const colorPalette: ColorPalette = {
  pink: {
    rose: "#FFE7E7",
    mauve: "#eed4de",
    salmon: "#ee7092",
    vividCerise: "#E40C88",
    violetRed: "#C61877",
    shockingPink: "#FF00B5",
    pastel: "#FEB6C2",
    dark: "#7F596F",
    black: "#523A48",
  },
  blue: {
    powder: "#b7c2e6",
    dark: "#3126b1",
  },
  purple: {
    base: "#592eb0",
  },
  orange: {
    base: "#f37d67",
    monaLisa: "#FF9787",
    cornflowerLilac: "#FFB5AA",
    light: "#FEDFE1",
    dark: "#806450",
  },
  white: {
    base: "#faf9f9",
    warm: "#DBD8D6",
  },
  gray: {
    base: "#C8C5C3",
    dark: "#B4B1B0",
    light: "#D6CDC6",
  },
  black: {
    alt: "#4f4f4f",
    light: "#2B2B2B",
    base: "#000",
    warm: "#221A10",
    background: "#2C2525",
  },
} as const;

interface SemanticColors {
  bodyBackground: string;
  bodyForeground: string;
  bodyForegroundAlt: string;
  bodyBackgroundAlt: string;
  bodyBackgroundChecked: string;
  tableBackgroundDark: string;
  tableBackgroundLight: string;
  bodyText: string;
  cardBackground: string;
  cardStandoutBackground: string;
  cardHover: string;
  buttonBackground: string;
  buttonBackgroundDisabled: string;
  buttonBackgroundHover: string;
  linkText: string;
  linkTextHot: string;
  interactiveElementOutline: string;
  nonInteractiveElementOutline: string;
}

const semanticColorsLightTheme: SemanticColors = {
  bodyText: colorPalette.black.base,
  bodyForeground: colorPalette.black.base,
  bodyForegroundAlt: colorPalette.pink.shockingPink,
  bodyBackground: colorPalette.pink.rose,
  bodyBackgroundChecked: colorPalette.pink.pastel,
  bodyBackgroundAlt: colorPalette.orange.light,
  tableBackgroundDark: colorPalette.pink.pastel,
  tableBackgroundLight: colorPalette.pink.mauve,
  cardBackground: colorPalette.pink.pastel,
  cardStandoutBackground: colorPalette.pink.pastel,
  cardHover: colorPalette.orange.base,
  buttonBackground: colorPalette.orange.base,
  buttonBackgroundDisabled: colorPalette.orange.light,
  buttonBackgroundHover: colorPalette.orange.cornflowerLilac,
  linkText: colorPalette.pink.black,
  linkTextHot: colorPalette.pink.violetRed,
  interactiveElementOutline: colorPalette.black.base,
  nonInteractiveElementOutline: colorPalette.pink.salmon,
};

const semanticColorsDarkTheme: SemanticColors = {
  bodyText: colorPalette.white.base,
  bodyForeground: colorPalette.white.base,
  bodyForegroundAlt: colorPalette.pink.shockingPink,
  bodyBackground: colorPalette.black.background,
  bodyBackgroundChecked: colorPalette.orange.monaLisa,
  bodyBackgroundAlt: colorPalette.orange.base,
  tableBackgroundDark: colorPalette.black.light,
  tableBackgroundLight: colorPalette.black.alt,
  cardStandoutBackground: colorPalette.black.warm,
  cardBackground: colorPalette.pink.pastel,
  cardHover: colorPalette.orange.base,
  buttonBackground: colorPalette.orange.base,
  buttonBackgroundDisabled: colorPalette.orange.light,
  buttonBackgroundHover: colorPalette.orange.cornflowerLilac,
  linkText: colorPalette.black.background,
  linkTextHot: colorPalette.pink.shockingPink,
  interactiveElementOutline: colorPalette.white.base,
  nonInteractiveElementOutline: colorPalette.pink.dark,
};

export { colorPalette, semanticColorsDarkTheme, semanticColorsLightTheme };
export type { ColorPalette, SemanticColors };
