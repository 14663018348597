import { Shadows } from "./shadows";
import { colorPalette, ColorPalette, SemanticColors, semanticColorsDarkTheme, semanticColorsLightTheme } from "./color";
import { shadows } from "./shadows";

export interface Theme {
  colorPalette: ColorPalette;
  semanticColors: SemanticColors;
  breakpoints: typeof breakpoints;
  mediaQueries: MediaQueries;
  shadows: Shadows;
}

// 540, 740, 960, 1180, 1540
export const breakpoints = {
  xSmall: 540,
  small: 740,
  medium: 960,
  mediumLarge: 1180,
  large: 1540,
  xLarge: 1920,
};

export const imageSizes = {
  small: 300,
  small_mobile: 320,
  large: 775,
  large_mobile: 320,
};

interface MediaQueries {
  xSmallMin: string;
  xSmallMax: string;
  smallMin: string;
  smallMax: string;
  mediumMin: string;
  mediumMax: string;
  largeMin: string;
  largeMax: string;
  xLargeMin: string;
  xLargeMax: string;
}

const mediaQueries: MediaQueries = {
  xSmallMin: `@media screen and (min-width: ${breakpoints.xSmall}px)`,
  xSmallMax: `@media screen and (max-width: ${breakpoints.xSmall}px)`,
  smallMin: `@media screen and (min-width: ${breakpoints.small}px)`,
  smallMax: `@media screen and (max-width: ${breakpoints.small}px)`,
  mediumMin: `@media screen and (min-width: ${breakpoints.medium}px)`,
  mediumMax: `@media screen and (max-width: ${breakpoints.medium}px)`,
  largeMin: `@media screen and (min-width: ${breakpoints.large}px)`,
  largeMax: `@media screen and (max-width: ${breakpoints.large}px)`,
  xLargeMin: `@media screen and (min-width: ${breakpoints.xLarge}px)`,
  xLargeMax: `@media screen and (max-width: ${breakpoints.xLarge}px)`,
};

const baseTheme = {
  colorPalette: colorPalette,
  shadows: shadows,
  breakpoints: breakpoints,
  mediaQueries,
};

const lightTheme: Theme = {
  ...baseTheme,
  semanticColors: semanticColorsLightTheme,
};

const darkTheme: Theme = {
  ...baseTheme,
  semanticColors: semanticColorsDarkTheme,
};

export { darkTheme, lightTheme, mediaQueries };
