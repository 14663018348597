import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lightTheme, darkTheme } from "../theme/theme";
import type { Theme } from "../theme/theme";
import type { AppState } from "../reducers/blogState";
import { semanticColorsDarkTheme, semanticColorsLightTheme } from "../theme/color";

interface IThemeProviderProps {
  children: JSX.Element | JSX.Element[];
  theme?: Theme;
}
const getCachedTheme = (): string | null | undefined => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem("isDarkTheme");
  }
};

const getThemeFromStorage = (): boolean => {
  if (typeof window !== "undefined") {
    const themeInLocalStorage = getCachedTheme();
    if (themeInLocalStorage !== null && themeInLocalStorage !== undefined && themeInLocalStorage !== "undefined") {
      return JSON.parse(themeInLocalStorage);
    }
  }
  return false;
};

const supportsDarkMode = () => {
  if (typeof window !== "undefined") {
    console.log("MATCH MEDIA", window.matchMedia("(prefers-color-scheme: dark)").matches);
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  }
  return false;
};

export function saveDarkThemePreferenceToLocalStorage(pref: boolean) {
  if (typeof window !== "undefined") {
    console.log("Saving Dark Theme Preference");
    window.localStorage.setItem("isDarkTheme", JSON.stringify(pref));
  }
}

const ThemeProvider = (props: IThemeProviderProps): JSX.Element => {
  const dispatch = useDispatch();

  const setDarkTheme = (newState: boolean) =>
    dispatch({
      type: "SET_IS_DARK",
      isDark: newState,
    });

  useEffect(() => {
    const theme = getCachedTheme();
    if (supportsDarkMode() && (theme === null || theme === undefined)) {
      saveDarkThemePreferenceToLocalStorage(true);
      setDarkTheme(true);
    } else {
      if (theme !== null && theme !== undefined) {
        const isDark = getThemeFromStorage();
        setDarkTheme(isDark);
      } else {
        // defaults to light theme
        saveDarkThemePreferenceToLocalStorage(false);
      }
    }
  }, []);

  return <>{props.children}</>;
};

const setTheme = (): void => {
  const isDark = useSelector((state: AppState) => state.isDark);
  const theme = isDark ? semanticColorsDarkTheme : semanticColorsLightTheme;

  if (typeof window !== "undefined") {
    const root = document.documentElement;
    Object.keys(theme).forEach((key) => {
      root.style.setProperty(`--${key}`, theme[key]);
    });
  }
};

const useTheme = (): Theme => {
  return useSelector((state: AppState) => state.isDark) ? darkTheme : lightTheme;
};

export { ThemeProvider, useTheme, setTheme };
